import React from "react";
import { css } from "@emotion/core";
import { DateTime } from "luxon";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/Seo";
import ResponsiveContainer from "../components/ResponsiveContainer";
import ContentfulRichText from "../components/ContentfulRichText";
import Paragraph from "../components/Paragraph";
import Heading from "../components/Heading";
import CallToAction from "../components/CallToAction";
import BlogItem from "../components/BlogItem";
import SmartLink from "../components/SmartLink";

import { BREAKPOINT_SPACING } from "../css-vars";

import { mediaQuery, pixelsToRem, createSpacingUnitMediaQueries } from "../utils/css-helpers";
import { createBlogListUrl, createBlogExcerpt } from "../utils/blog";

import { blogUrl } from "../routes";
import SmartImg from "../components/SmartImg";

export const query = graphql`
  query($slug: String!, $paginationPrevSlug: String, $paginationNextSlug: String) {
    post: contentfulBlogPost(slug: {
      eq: $slug
    }) {
      ...BlogPostMeta
      id
      seoTitle
      seoMetaDescription {
        seoMetaDescription
      }
      content {
        json
      }
    }
    prevPost: contentfulBlogPost(slug: {eq: $paginationPrevSlug}) {
      ...BlogPostMeta
    }
    nextPost: contentfulBlogPost(slug: {eq: $paginationNextSlug}) {
      ...BlogPostMeta
    }
  }
`;

const BlogPost = ({
  data: {
    post,
    prevPost,
    nextPost,
  },
  pageContext: {
    slug,
    paginationNextSlug,
    paginationPrevSlug,
  },
}) => {
  const {
    seoTitle,
    category,
    date,
    heading,
    coverImage: coverImageObject,
    content,
  } = post;

  const coverImage = coverImageObject ? coverImageObject.localFile.childImageSharp.fluid : null;

  // WHY: LongText fields in Contentful has a weird structure: if empty they return null,
  //      but if filled they return an object with the actual content in a homonym child.
  //      (https://github.com/gatsbyjs/gatsby/issues/3205)
  const {
    seoMetaDescription = createBlogExcerpt(content, 200),
  } = post.seoMetaDescription || {};

  const strapline = category ?
    <SmartLink link={createBlogListUrl(1, category)} css={css`
      :hover,
      :focus {
        text-decoration: underline;
      }
    `}>{category}</SmartLink> :
    `Blog`;

  return (
    <Layout>
      <SEO title={seoTitle} description={seoMetaDescription} image={coverImage && coverImage.src} />

      <ResponsiveContainer spacingTop="half" spacingBottom="half">

        <Heading tag="h1" align="center" spacingTop="none" strapline={strapline}>{heading}</Heading>

        <Paragraph
          tag="time"
          align="center"
          level="medium"
          spacingTop="none"
          spacingBottom="full"
          dateTime={DateTime.fromISO(date).toISODate()}
          css={css`
            display: block;
          `}
        >
          {DateTime.fromISO(date).toFormat(`MMMM d, y`)}
        </Paragraph>

        {coverImage && <SmartImg fluid={coverImage} alt=""/>}

      </ResponsiveContainer>

      <ResponsiveContainer spacingTop="none" width="thin" tag="article">

        <ContentfulRichText json={content.json} blogStyle={true}/>

        {/* The article may end with a portrait picture floating on the left,
            so this div ensures the space is always correct */}
        <div css={css`clear: both`}/>

      </ResponsiveContainer>

      <ResponsiveContainer spacingTop="full">
        <Heading
          tag="h2"
          level="h2"
          strapline={<SmartLink link={createBlogListUrl(1)} css={css`
            :hover,
            :focus {
              text-decoration: underline;
            }
          `}>Blog</SmartLink>}
          spacingTop="none"
          css={css`
            margin-bottom: 1.5em;
          `}
        >
          More from ServiceLink
        </Heading>
        <div css={css`
          // WHY: Stops floated images destroying the footer below
          clear: both;

          ${mediaQuery({ minWidth: 620 })} {
            display: flex;
          }

          article {

            ${mediaQuery({ minWidth: 620 })} {
              width: calc(50% - ${7 / 2}%);
            }

            &:nth-of-type(1) {
              margin-bottom: calc(${pixelsToRem(BREAKPOINT_SPACING.tiny)} / 2);

              ${mediaQuery({ minWidth: 620 })} {
                margin-bottom: 0;
                margin-right: 7%;
              }
            }
          }
        `}>
          {prevPost && paginationPrevSlug && <BlogItem {...prevPost} title="Previous Post" />}
          {nextPost && paginationNextSlug && <BlogItem {...nextPost} title="Next Post" />}
        </div>
        <CallToAction link={blogUrl} caption="View all posts" align="center" css={css`
          ${createSpacingUnitMediaQueries({
            'margin-top': `calc(SPACING_UNIT / 2)`,
          })}
        `} />
      </ResponsiveContainer>

    </Layout>
  );
};

export default BlogPost;
